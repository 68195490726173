import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faTwitter,
    faSquareFacebook,
    faInstagram,
    faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import {faPhone, faFax, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

function Footer() {
    return (
        <Grid2
            className="footer-container"
            container
            paddingY={{xs: 1, sm: 2, md: 4, lg: 8}}
        >
            <Grid2 className="footer-social-gallery" xs={12} md={6}>
                <h3>Social Media</h3>
                <div className="footer-social-media-links">
                    <a
                        href="https://www.facebook.com/pages/Arthur-Moniz-Gallery/62585841559"
                        aria-label="arthur moniz gallery facebook page"
                    >
                        <FontAwesomeIcon icon={faSquareFacebook} size="2xl" />
                    </a>
                    <a
                        href="https://twitter.com/artmonizgallery?lang=en"
                        aria-label="arthur moniz gallery twitter page"
                    >
                        <FontAwesomeIcon icon={faTwitter} size="2xl" />
                    </a>
                    <a
                        href="http://www.thepictaram.club/toplace/319990704/"
                        aria-label="arthur moniz gallery instagram page"
                    >
                        <FontAwesomeIcon icon={faInstagram} size="2xl" />
                    </a>
                    <a
                        href="https://www.linkedin.com/company/arthur-moniz-gallery"
                        aria-label="arthur moniz gallery linked in page"
                    >
                        <FontAwesomeIcon icon={faLinkedin} size="2xl" />
                    </a>
                </div>
            </Grid2>
            <Grid2 className="footer-contact-gallery" xs={12} md={6}>
                <h3>Contact us!</h3>
                <div className="footer-contact-links">
                    <a
                        href="tel:508-992-6050"
                        aria-label="call arthur moniz gallery"
                    >
                        <FontAwesomeIcon icon={faPhone} size="2xl" />
                    </a>
                    <a
                        href="fax:847-555-5555"
                        aria-label="fax arthur moniz gallery"
                    >
                        <FontAwesomeIcon icon={faFax} size="2xl" />
                    </a>
                    <Link to="/contact">
                        <FontAwesomeIcon icon={faEnvelope} size="2xl" />
                    </Link>
                </div>
            </Grid2>
        </Grid2>
    );
}

export default Footer;
