import axios from "axios";
import {useEffect, useState} from "react";
import {Button, Box} from "@mui/material";
import {useAuth0} from "@auth0/auth0-react";
import Alerts from "../Alerts";
import {TextareaAutosize} from "@mui/material";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function TextEditing() {
    const {user} = useAuth0();
    const [specialBodyText, setSpecialBodyText] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [severityAlert, setSeverityAlert] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    function updateWeeklySpecialText() {
        if (specialBodyText.length > 0) {
            const config = {
                headers: {
                    "Content-Type": "application/JSON",
                    "x-api-key": user === undefined ? "" : user.sub,
                },
            };
            axios
                .post(
                    `${API_ENDPOINT}/specialtext`,
                    JSON.stringify({
                        WeeklySpecialBody: specialBodyText,
                    }),
                    config
                )
                .then((response) => {
                    setShowAlert(true);
                    setSeverityAlert("success");
                    setAlertMessage(`New Weekly special text has been added`);
                    clearAllValues();
                })
                .catch((error) => {
                    setShowAlert(true);
                    setSeverityAlert("error");
                    setAlertMessage(
                        `Weekly special text encountered an issue!`
                    );
                });
        } else {
            setShowAlert(true);
            setSeverityAlert("error");
            setAlertMessage(`No text found, input text and try again!`);
        }
    }

    function clearAllValues() {
        setSpecialBodyText("");
    }

    const onChange = (evt) => {
        var value = evt.target.value;
        setSpecialBodyText(value);
    };

    useEffect(() => {}, [specialBodyText]);

    return (
        <>
            <Box padding={2}>
                <TextareaAutosize
                    value={specialBodyText}
                    minRows={5}
                    onChange={onChange}
                    required
                    size="lg"
                ></TextareaAutosize>
            </Box>
            <Button variant="contained" onClick={updateWeeklySpecialText}>
                Update Text
            </Button>
            {showAlert && (
                <Alerts
                    severityAlert={severityAlert}
                    alertMessage={alertMessage}
                    openStatus={true}
                    setOpenToFalse={() => setShowAlert(false)}
                />
            )}
        </>
    );
}
